import { isUuid } from "@screencloud/uuid";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import { AppContext } from "./AppContextProvider/AppContext";
import AdminHeader from "./components/Admin/Header";
import ComponentLoader, {
  Context,
} from "./components/ComponentLoader/ComponentLoader";
import { GlobalMainStyles } from "./components/Main/styles";
import MainMenu from "./components/MainMenu";
import {
  FROM_DETAIL_PAGE_QUERYSTRING,
  Locale,
  OnBoardingRefType,
} from "./constants/constants";
import { FEATURE_FLAGS_ENUM } from "./constants/featureFlag";
import ImageBlocker from "./images/ghost";
import { ChannelDetailMode } from "./pages/Channels/ChannelDetail";
import GenericNotFound from "./pages/GenericNotFound";
import { PlaylistDetailMode } from "./pages/Playlists/PlaylistDetail";
import { SystemUserClaims } from "./state/session/StudioSession";
import { compose } from "./utils/compose";
import { parse } from "./utils/queryString";
import { subscribeToDocumentUpdates } from "./state/liveUpdateManager";
import {
  EntityType,
  UnsubscribeCallback,
} from "@screencloud/signage-firestore-client";
import { AppContextType } from "./AppContextProvider/type";
import { canAccessScreensManager } from "./domains/screensManager/permissions";
import ActivateBanner from "./components/ActivateBanner";
import { ScreenCloudContactUrl } from "./constants/url";
import { canAccessSSOTab } from "./domains/sso/permissions";
import { getWhiteLabelStyles } from "./helpers/whiteLabel";
import {
  getOrgOnboardingPreferences,
  getUserOnboardingPreferences,
  installDefaultChannnel,
  onBoardingSetup,
  shouldInstallDemoContent,
  updateUserOnboarding,
} from "./helpers/onboardingHelper";
import { observeElement } from "./utils/observeElement";
import { onCmdOPress } from "./helpers/keyPressHelper";
export interface Props {
  onLanguageChange: (locale: Locale) => void;
}

const withData = compose(withRouter);

const shouldHideMainMenuForView = [
  "organization",
  "organizations",
  "users",
  "spaces",
  "developer",
  "account",
  "billing",
  "themes",
  "paypal",
  "directdebit",
  "system",
  "people",
  "groups",
  "logs",
  "playback-logs",
  "proof-of-play",
  "credentials",
  "migrate",
  "finish-migration",
  "finish-process",
  "payment-update",
  "logout",
  "relationship",
  "screens-manager",
  "usb-provisioning",
  "verify-email",
  "unsubscribe-notification",
];
const shouldHideActivateBannerForView = ["unsubscribe-notification"];
const adminPagePaths = [
  "organization",
  "users",
  "spaces",
  "developer",
  "account",
  "billing",
  "themes",
  "people",
  "groups",
  "logs",
  "playback-logs",
  "proof-of-play",
  "credentials",
  "verify-email",
];

const redirectIfMaintenance = (
  renderFn: () => JSX.Element,
  isBillingMaintenance: boolean
) => {
  if (isBillingMaintenance) {
    return <Redirect to={`/billing/maintenance`} />;
  }

  return renderFn();
};

class RouteController extends React.Component<Props & RouteComponentProps, {}> {
  public static contextType = AppContext;
  public context: AppContextType;

  private unsubscribe: UnsubscribeCallback;
  private removeCmdOListener: () => void;

  public shouldStartOnboardingTour() {
    const {
      isOnboardingExist: isOrgOnboardingExist,
      isOnboardingV1Done,
      isOnboardingV1Skip,
      isOnbardingV2Done,
      isOnboardingV2Skip,
    } = getOrgOnboardingPreferences(this.context.currentOrg);

    const {
      isOnboardingTourDone,
      isOnboardingTourSkip,
    } = getUserOnboardingPreferences(this.context.currentUser);

    if (!isOrgOnboardingExist) {
      return false;
    }

    return !(
      isOnboardingTourDone ||
      isOnboardingTourSkip ||
      isOnboardingV1Done ||
      isOnboardingV1Skip ||
      isOnbardingV2Done ||
      isOnboardingV2Skip
    );
  }

  public renderSupportHeader() {
    const claims: SystemUserClaims = this.context.user.claims as any;

    return (
      <div className="org-draft inspector">
        <span>
          {claims.orgName} ({claims.email})
        </span>
        <button
          onClick={() => {
            this.context.sessions.endSystemAccessSession();
            this.context.history.replace("/logout");
          }}
        >
          Logout
        </button>
      </div>
    );
  }

  async componentDidMount() {
    this.subscribeToLiveUpdate();
    this.removeCmdOListener = onCmdOPress(() => {
      updateUserOnboarding(OnBoardingRefType.ORG_ONBOARDING_TOUR_DONE, false);
      updateUserOnboarding(OnBoardingRefType.ORG_ONBOARDING_TOUR_SKIP, false);
      this.startTour();
    });

    if (this.shouldStartOnboardingTour()) {
      const shouldInstallChannel = await shouldInstallDemoContent({
        currentOrg: this.context.currentOrg,
      });
      if (shouldInstallChannel) {
        installDefaultChannnel({
          spaceId: this.context.currentSpace?.id || "",
          orgName: this.context.currentOrg?.name || "",
          email: this.context.user.claims.email || "",
          rootFolderId: this.context.currentSpace?.rootFolderId || "",
          countryCode:
            this.context.currentOrg?.preferences?.settings[
              OnBoardingRefType.ORG_COUNTRY_CODE
            ] || "",
        });
      }
      await updateUserOnboarding(
        OnBoardingRefType.VISITED_SCREEN_PAIRING,
        false
      );

      observeElement({
        nodeId: "activate-banner",
        callback: () => this.startTour(),
      });
    }
  }

  componentWillUnmount() {
    this.unsubscribeToLiveUpdate();
    if (this.removeCmdOListener) {
      this.removeCmdOListener();
    }
  }

  subscribeToLiveUpdate = () => {
    this.unsubscribe = subscribeToDocumentUpdates(
      this.context.user?.claims?.orgId,
      EntityType.SPACE,
      this.context.currentSpace?.id || "",
      async () => {
        await this.context.refetchCurrentActiveSpace();
      },
      true
    );
  };

  unsubscribeToLiveUpdate = () => {
    this.unsubscribe();
  };

  startTour = () => {
    const steps = [
      {
        element: ".menu #screens-button",
        popover: {
          title: "Get Started with Screens.",
          description:
            "Here you can pair and manage your devices, and set which content is playing on your screens.",
          side: "right" as const,
          align: "start" as const,
        },
        showButtons: ["next, close"],
      },
      {
        element: ".menu #media-group",
        popover: {
          title: "Upload and Create.",
          description:
            "Upload and manage images, videos, documents, and display webpages, or create custom dashboards and canvases.",
          side: "right" as const,
          align: "start" as const,
        },
        showButtons: ["next, close"],
      },
      {
        element: ".menu #apps-button",
        popover: {
          title: "Level-up with Apps.",
          description:
            "Supercharge your screen by adding dynamic content from over 70 apps, including social media, engagement tools, and many more.",
          side: "right" as const,
          align: "start" as const,
        },
        showButtons: ["next, close"],
      },
      {
        element: ".menu #organize-group",
        popover: {
          title: "Organize and Schedule.",
          description:
            "Organize your content into simple Playlists, these can be added to Channels to play them across multiple zones or schedule specific times for them to display on your screens.",
          side: "right" as const,
          align: "start" as const,
        },
        showButtons: ["next, close"],
      },
    ];

    onBoardingSetup({
      steps,
      showProgress: true,
      onComplete: () => {
        updateUserOnboarding(OnBoardingRefType.ORG_ONBOARDING_TOUR_DONE, true);
      },
      onClose: () => {
        updateUserOnboarding(OnBoardingRefType.ORG_ONBOARDING_TOUR_SKIP, true);
      },
    });
  };

  public render() {
    const path = this.props.location.pathname.split("/")[1];
    const isAdminPage = adminPagePaths.includes(path);
    const { isSystemUser } = this.context.user;
    const isMigration =
      this.context.migrationOrgIds.length !== 0 &&
      this.context.migrationOrgIds.find(
        (orgId) => orgId === this.context.currentOrg?.id
      );
    const defaultRedirect = this.context.getDefaultRedirectPath(
      this.context.currentPermissions
    );
    const shouldHideMainMenu = shouldHideMainMenuForView.includes(path);
    const shouldHideActivateBanner = shouldHideActivateBannerForView.includes(
      path
    );
    const whiteLabelStyles = getWhiteLabelStyles(this.context);
    const isBillingMaintenance = this.context.shouldShowFeature(
      FEATURE_FLAGS_ENUM.BILLING_MAINTENANCE
    );

    return (
      <>
        {!shouldHideActivateBanner && (
          <ActivateBanner isFullWidth={shouldHideMainMenu} />
        )}
        {isSystemUser && this.renderSupportHeader()}
        <div className={`App${isAdminPage ? " Admin" : ""}`}>
          {this.context.currentOrg?.isDraft && !isMigration && (
            <div className="org-draft">
              <div>
                We've finished moving everything over for you. Please take a
                look around and make sure everything looks okay.
              </div>
            </div>
          )}
          {!shouldHideMainMenu && (
            <MainMenu
              onLanguageChange={(locale) => this.props.onLanguageChange(locale)}
            />
          )}

          {isAdminPage ? (
            <>
              <GlobalMainStyles {...whiteLabelStyles} />
              <AdminHeader
                canAccessOnlyAdminSection={defaultRedirect === "account"}
                givenName={this.context.user.claims.givenName!}
                familyName={this.context.user.claims.familyName!}
                picture={this.context.user.claims.picture}
                orgSelected={Number(this.context.user.key)}
              />
            </>
          ) : (
            <GlobalMainStyles {...whiteLabelStyles} />
          )}

          <div className="main-content">
            {isMigration || path.includes("migrate") ? (
              <Switch>
                <Route
                  exact
                  path={`/paypal`}
                  render={() =>
                    redirectIfMaintenance(() => {
                      return (
                        <ComponentLoader
                          contextName={Context.Billing}
                          componentName={`BillingCallback`}
                          props={{ brand: "paypal" }}
                        />
                      );
                    }, isBillingMaintenance)
                  }
                />
                <Route
                  exact
                  path={`/migrate/:orgId`}
                  render={(props) => {
                    const { orgId } = props.match.params;
                    if (orgId && isUuid(orgId)) {
                      return (
                        <ComponentLoader
                          contextName={Context.CustomerMigration}
                          componentName={"CustomerMigration"}
                          title="Customer Migrate - Content"
                        />
                      );
                    } else {
                      return <div>This is not an org you are looking for,</div>;
                    }
                  }}
                />
                <Route
                  exact
                  path={`/logout`}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.Logout}
                      componentName={"Logout"}
                      title="Logout - ScreenCloud"
                    />
                  )}
                />
                <Route
                  exact
                  path={`*`}
                  render={() => (
                    <Redirect to={`/migrate/${this.context.currentOrg?.id}`} />
                  )}
                />
              </Switch>
            ) : (
              <Switch>
                <Route
                  exact
                  path={`/`}
                  render={() => <Redirect to={`/${defaultRedirect}`} />}
                />
                <Route
                  exact
                  path={`/screens-manager`}
                  render={() => {
                    if (canAccessScreensManager(this.context)) {
                      return (
                        <ComponentLoader
                          contextName={Context.Screens}
                          componentName={"ScreensManager"}
                          title="Screens Manager - ScreenCloud"
                        />
                      );
                    } else {
                      return <Redirect to={`/${defaultRedirect}`} />;
                    }
                  }}
                />
                <Route
                  exact
                  path={`/usb-provisioning`}
                  render={() => {
                    if (canAccessScreensManager(this.context)) {
                      return (
                        <ComponentLoader
                          contextName={Context.Screens}
                          componentName={"UsbProvisioning"}
                          title="USB Provisioning - ScreenCloud"
                        />
                      );
                    } else {
                      return <Redirect to={`/${defaultRedirect}`} />;
                    }
                  }}
                />
                <Route
                  exact
                  path={`/screens/:screenId?`}
                  render={(props) => {
                    if (
                      props.match.params.screenId &&
                      isUuid(props.match.params.screenId)
                    ) {
                      return (
                        <ComponentLoader
                          contextName={Context.Screens}
                          componentName={"ScreenDetail"}
                          title="Screens - ScreenCloud"
                        />
                      );
                    } else {
                      return (
                        <ComponentLoader
                          contextName={Context.Screens}
                          componentName={"ScreenList"}
                          title="Screens - ScreenCloud"
                        />
                      );
                    }
                  }}
                />
                <Route
                  exact
                  path={`/playlists/:playlistId?/:mode?`}
                  render={(props) => {
                    const { match, history } = props;
                    const { playlistId, mode: paramMode } = match.params;
                    if (playlistId && isUuid(playlistId)) {
                      const queryStrings = parse(location.search, {});
                      const isNew = queryStrings.new === "true";
                      const isFullScreen = queryStrings.fullscreen === "true";
                      const mode =
                        paramMode === "edit" || isNew
                          ? PlaylistDetailMode.Edit
                          : PlaylistDetailMode.Preview;
                      const onEditPlaylistClick = () =>
                        history.push(`/playlists/${playlistId}/edit`);
                      const onPreviewPlaylistClick = () =>
                        history.push(`/playlists/${playlistId}`);
                      const onPreviewPlaylistFromDetailPageClick = () =>
                        history.push(
                          `/playlists/${playlistId}?${FROM_DETAIL_PAGE_QUERYSTRING}=true`
                        );
                      const onPreviewFullscreen = () =>
                        history.push(
                          `/playlists/${playlistId}?fullscreen=true`
                        );
                      const onGoBackToPlaylistListPage = () =>
                        history.push(`/playlists/`);

                      const propsParam = {
                        playlistId,
                        mode,
                        isFullScreen,
                        onEditPlaylistClick,
                        onPreviewPlaylistClick,
                        onPreviewPlaylistFromDetailPageClick,
                        onGoBackToPlaylistListPage,
                        onPreviewFullscreen,
                      };
                      return (
                        <ComponentLoader
                          contextName={Context.Playlists}
                          props={propsParam}
                          componentName={"PlaylistDetail"}
                          title="Playlists - ScreenCloud"
                        />
                      );
                    } else {
                      return (
                        <ComponentLoader
                          contextName={Context.Playlists}
                          componentName={"Playlists"}
                          title="Playlists - ScreenCloud"
                        />
                      );
                    }
                  }}
                />
                <Route
                  exact
                  path={`/channels/:channelId?/:mode?`}
                  render={(props) => {
                    const { match, location, history } = props;
                    const { channelId, mode: paramMode } = match.params;
                    if (channelId && isUuid(channelId)) {
                      const queryStrings = parse(location.search, {});
                      const isFullScreen = queryStrings.fullscreen === "true";
                      const isNew = queryStrings.new === "true";
                      const mode =
                        paramMode === "edit"
                          ? ChannelDetailMode.Edit
                          : ChannelDetailMode.Preview;
                      const onEditChannelClick = () =>
                        history.push(`/channels/${channelId}/edit`);
                      const onPreviewChannelClick = () =>
                        history.push(`/channels/${channelId}`);
                      const onPreviewChannelFromDetailPageClick = () =>
                        history.push(
                          `/channels/${channelId}?${FROM_DETAIL_PAGE_QUERYSTRING}=true`
                        );
                      const onPreviewFullscreen = () =>
                        history.push(`/channels/${channelId}?fullscreen=true`);
                      const onBackToListPage = () => history.push(`/channels/`);

                      const propsParam = {
                        isFullScreen,
                        isNew,
                        mode,
                        onBackToListPage,
                        onEditChannelClick,
                        onPreviewChannelClick,
                        onPreviewChannelFromDetailPageClick,
                        onPreviewFullscreen,
                      };
                      return (
                        <ComponentLoader
                          contextName={Context.Channels}
                          props={propsParam}
                          componentName={"ChannelDetail"}
                          title="Channels - ScreenCloud"
                        />
                      );
                    } else {
                      return (
                        <ComponentLoader
                          contextName={Context.Channels}
                          componentName={"Channels"}
                          title="Channels - ScreenCloud"
                        />
                      );
                    }
                  }}
                />
                <Route
                  path={`/trash`}
                  render={() => {
                    if (
                      this.context.shouldShowFeature(
                        FEATURE_FLAGS_ENUM.RECYCLE_BIN
                      )
                    ) {
                      return (
                        <ComponentLoader
                          contextName={Context.Trashes}
                          componentName={"TrashPage"}
                          title="Trash - ScreenCloud"
                        />
                      );
                    } else {
                      return <Redirect to={`/${defaultRedirect}`} />;
                    }
                  }}
                />
                <Route
                  path={`/media/search/:fileId/preview`}
                  render={(props) => (
                    <ComponentLoader
                      contextName={Context.Medias}
                      props={{ isSearchMode: true, isPreview: true }}
                      componentName={"MediasList"}
                      title="Media - ScreenCloud"
                    />
                  )}
                />
                <Route
                  path={`/media/search`}
                  render={(props) => (
                    <ComponentLoader
                      contextName={Context.Medias}
                      props={{ isSearchMode: true }}
                      componentName={"MediasList"}
                      title="Media - ScreenCloud"
                    />
                  )}
                />
                <Route
                  path={`/media/shared/:folderId/:fileId/preview`}
                  render={(props) => (
                    <ComponentLoader
                      contextName={Context.Medias}
                      props={{
                        isInSharedFolderFromOtherSpaces: true,
                        isPreview: true,
                      }}
                      componentName={"MediasList"}
                      title="Media - ScreenCloud"
                    />
                  )}
                />
                <Route
                  path={`/media/shared/:folderId`}
                  render={(props) => (
                    <ComponentLoader
                      contextName={Context.Medias}
                      props={{ isInSharedFolderFromOtherSpaces: true }}
                      componentName={"MediasList"}
                      title="Media - ScreenCloud"
                    />
                  )}
                />
                <Route
                  path={`/media/shared`}
                  render={(props) => (
                    <ComponentLoader
                      contextName={Context.Medias}
                      props={{
                        isInRootSharedFolderFromOtherSpaces: true,
                        isInSharedFolderFromOtherSpaces: true,
                      }}
                      componentName={"MediasList"}
                      title="Media - ScreenCloud"
                    />
                  )}
                />
                <Route
                  path={`/media/:fileId/preview`}
                  render={(props) => (
                    <ComponentLoader
                      contextName={Context.Medias}
                      props={{ isPreview: true }}
                      componentName={"MediasList"}
                      title="Media - ScreenCloud"
                    />
                  )}
                />
                <Route
                  path={`/media/:folderId/:fileId/preview`}
                  render={(props) => (
                    <ComponentLoader
                      contextName={Context.Medias}
                      props={{ isPreview: true }}
                      componentName={"MediasList"}
                      title="Media - ScreenCloud"
                    />
                  )}
                />
                <Route
                  path={`/media/:folderId?`}
                  render={(props) => (
                    <ComponentLoader
                      contextName={Context.Medias}
                      componentName={"MediasList"}
                      title="Media - ScreenCloud"
                    />
                  )}
                />
                <Route
                  exact
                  path={`/canvas/:id?/:instanceId?`}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.Canvas}
                      props={{ isTemplate: false, isTemplateGallery: false }}
                      componentName={"Canvas"}
                      title={`Canvas`}
                    />
                  )}
                />
                <Route
                  exact
                  path={`/canvas-templates/:id?/:instanceId?`}
                  render={(props) => {
                    return (
                      <ComponentLoader
                        contextName={Context.Canvas}
                        props={{ isTemplate: true, isTemplateGallery: false }}
                        componentName={"Canvas"}
                        title={`Canvas`}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path={`/canvas-template-gallery/:id?/:instanceId?`}
                  render={(props) => {
                    return (
                      <ComponentLoader
                        contextName={Context.Canvas}
                        props={{ isTemplate: false, isTemplateGallery: true }}
                        componentName={"Canvas"}
                        title={`Canvas`}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path={`/apps-store/category/:categoryId/:appId?`}
                  render={(props) => {
                    const { categoryId, appId } = props.match.params;

                    return (
                      <ComponentLoader
                        contextName={Context.Apps}
                        componentName={"AppStore"}
                        title="App Store - ScreenCloud"
                        props={{ isAppStore: true, categoryId, appId }}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path={`/apps-shared/:id?/:instanceId?`}
                  render={(props) => (
                    <ComponentLoader
                      contextName={Context.Apps}
                      componentName={"AppsList"}
                      title="Apps - ScreenCloud"
                      props={{ isAppStore: false }}
                    />
                  )}
                />
                <Route
                  exact
                  path={`/apps/:id?/:instanceId?`}
                  render={(props) => (
                    <ComponentLoader
                      contextName={Context.Apps}
                      componentName={"AppsList"}
                      title="Apps - ScreenCloud"
                      props={{ isAppStore: false }}
                    />
                  )}
                />
                <Route
                  exact
                  path={`/links`}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.Links}
                      componentName={`Links`}
                      title="Links - ScreenCloud"
                    />
                  )}
                />
                <Route
                  exact
                  path={`/dashboards`}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.Sites}
                      componentName={`Sites`}
                      title="Dashboards - ScreenCloud"
                    />
                  )}
                />
                <Route
                  exact
                  path={`/links/callback`}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.Links}
                      componentName={`LinkCallback`}
                      title="Link - ScreenCloud"
                    />
                  )}
                />
                {/* Admin routes */}
                <Route
                  exact
                  path={`/account`}
                  render={() => (
                    <ComponentLoader
                      props={{ onLanguageChange: this.props.onLanguageChange }}
                      contextName={Context.Account}
                      componentName={`Account`}
                      title="Account Settings - ScreenCloud"
                    />
                  )}
                />
                <Route
                  exact
                  path={`/organization`}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.Organization}
                      componentName={`Organization`}
                      title="Organization - ScreenCloud"
                    />
                  )}
                />
                <Route
                  exact
                  path={`/organization/sso-logs`}
                  render={() => {
                    if (canAccessSSOTab(this.context)) {
                      return (
                        <ComponentLoader
                          contextName={Context.OrganizationSSOLogs}
                          componentName={`OrganizationSSOLogs`}
                          title="Single Sign-On - ScreenCloud"
                        />
                      );
                    } else {
                      return <Redirect to={`/${defaultRedirect}`} />;
                    }
                  }}
                />
                <Route
                  exact
                  path={`/organization/sso`}
                  render={() => {
                    if (canAccessSSOTab(this.context)) {
                      return (
                        <ComponentLoader
                          contextName={Context.OrganizationSSO}
                          componentName={`OrganizationSSO`}
                          title="Organization - ScreenCloud"
                        />
                      );
                    } else {
                      return <Redirect to={`/${defaultRedirect}`} />;
                    }
                  }}
                />
                <Route
                  exact
                  path={`/organization/cancel`}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.Organization}
                      componentName={`Organization`}
                      title="Organization - ScreenCloud"
                    />
                  )}
                />
                <Route
                  exact
                  path={`/paypal`}
                  render={() =>
                    redirectIfMaintenance(() => {
                      if (this.context.currentOrg?.isDraft) {
                        return <Redirect to="/screens" />;
                      }
                      return (
                        <ComponentLoader
                          contextName={Context.Billing}
                          componentName={`BillingCallback`}
                          props={{ brand: "paypal" }}
                        />
                      );
                    }, isBillingMaintenance)
                  }
                />
                <Route
                  exact
                  path={`/directdebit`}
                  render={() =>
                    redirectIfMaintenance(() => {
                      if (this.context.currentOrg?.isDraft) {
                        return <Redirect to="/screens" />;
                      }
                      return (
                        <ComponentLoader
                          contextName={Context.Billing}
                          componentName={`BillingCallback`}
                          props={{ brand: "directdebit" }}
                        />
                      );
                    }, isBillingMaintenance)
                  }
                />
                <Route
                  exact
                  path={`/verify-email`}
                  render={() => {
                    if (this.context.isActiveUser) {
                      return <Redirect to="/screens" />;
                    }
                    return (
                      <ComponentLoader
                        contextName={Context.VerifyEmail}
                        componentName={`VerifyEmailPage`}
                        title="Verify - ScreenCloud"
                      />
                    );
                  }}
                />
                <Route
                  path={["/billing/maintenance"]}
                  render={() => {
                    return (
                      <ComponentLoader
                        contextName={Context.BillingMaintenance}
                        componentName={`Maintenance`}
                        title="Billing - Maintenance"
                      />
                    );
                  }}
                />

                <Route
                  path={[
                    "/billing/latest/account/:spaceId",
                    "/billing/latest/complete/:provider",
                    "/billing/latest/manage",
                    "/billing/latest",
                  ]}
                  render={() =>
                    redirectIfMaintenance(() => {
                      if (this.context.currentOrg?.isDraft) {
                        return <Redirect to="/screens" />;
                      }
                      return (
                        <ComponentLoader
                          contextName={Context.BillingLatest}
                          componentName={`Index`}
                          title="Billing - ScreenCloud"
                        />
                      );
                    }, isBillingMaintenance)
                  }
                />
                <Route
                  path={`/billing/:action?/:spaceId?`}
                  render={() =>
                    redirectIfMaintenance(() => {
                      if (this.context.currentOrg?.isDraft) {
                        return <Redirect to="/screens" />;
                      }
                      return (
                        <ComponentLoader
                          contextName={Context.Billing}
                          componentName={`Billing`}
                          title="Billing - ScreenCloud"
                        />
                      );
                    }, isBillingMaintenance)
                  }
                />
                <Route
                  path={`/metrics`}
                  render={() => {
                    return (
                      <ComponentLoader
                        contextName={Context.Metrics}
                        componentName={`Metrics`}
                        title="Metrics - ScreenCloud"
                      />
                    );
                  }}
                />
                <Route
                  path={`/relationship/callback/:gateway/:currency/:complete?`}
                  render={() =>
                    redirectIfMaintenance(() => {
                      return (
                        <ComponentLoader
                          contextName={Context.Billing}
                          componentName={`BillingRelationshipCallback`}
                        />
                      );
                    }, isBillingMaintenance)
                  }
                />
                <Route
                  exact
                  path={`/people`}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.People}
                      componentName={`People`}
                      title="People - ScreenCloud"
                    />
                  )}
                />
                <Route
                  exact
                  path={`/people/owners`}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.PeopleOwners}
                      componentName={`PeopleOwners`}
                      title="Owners - ScreenCloud"
                    />
                  )}
                />
                <Route
                  exact
                  path={`/people/sso`}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.PeopleSSO}
                      componentName={`PeopleSSO`}
                    />
                  )}
                />
                <Route
                  exact
                  path={`/groups`}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.PeopleGroups}
                      componentName={`PeopleGroup`}
                      title="Groups - ScreenCloud"
                    />
                  )}
                />
                <Route
                  exact
                  path={`/groups/roles`}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.PeopleGroupsRoles}
                      componentName={`PeopleGroupRole`}
                      title="Roles - ScreenCloud"
                    />
                  )}
                />
                <Route
                  exact
                  path={`/playback-logs`}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.PlaybackLogs}
                      componentName={`PlaybackLogs`}
                      title="Playback Logs - ScreenCloud"
                    />
                  )}
                />
                <Route
                  exact
                  path={`/proof-of-play`}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.ProofOfPlay}
                      componentName={`ProofOfPlay`}
                      title="Proof of Play - ScreenCloud"
                    />
                  )}
                />
                <Route
                  exact
                  path={`/proof-of-play/:contentId`}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.ProofOfPlayContentDetail}
                      componentName={`ProofOfPlayContentDetail`}
                      title="Proof of Play - ScreenCloud"
                    />
                  )}
                />
                <Route
                  exact
                  path={`/logs`}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.Logs}
                      componentName={`Logs`}
                      title="Audit Logs - ScreenCloud"
                    />
                  )}
                />
                <Route
                  exact
                  path={`/credentials`}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.Credentials}
                      componentName={`Credentials`}
                      title="Credentials - ScreenCloud"
                    />
                  )}
                />
                <Route
                  exact
                  path={`/spaces/:action?/:spaceId?`}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.Spaces}
                      componentName={`Spaces`}
                      title="Spaces - ScreenCloud"
                    />
                  )}
                />
                <Route
                  exact
                  path={`/developer/:action?/:apiAccessTokenId?`}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.Developer}
                      componentName={`Developer`}
                      title="Developer - ScreenCloud"
                    />
                  )}
                />
                <Route
                  exact
                  path={`/themes`}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.Themes}
                      componentName={`Themes`}
                      title="Themes - ScreenCloud"
                    />
                  )}
                />
                <Route
                  exact
                  path={`/themes/fonts`}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.Font}
                      componentName={`Fonts`}
                      title="Fonts - ScreenCloud"
                    />
                  )}
                />
                <Route
                  exact
                  path={"/unsubscribe-notification"}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.UnsubscribeNotification}
                      componentName={"UnsubscribeNotification"}
                    />
                  )}
                />
                <Route
                  exact
                  path={`/logout`}
                  render={() => (
                    <ComponentLoader
                      contextName={Context.Logout}
                      componentName={"Logout"}
                      title="Logout - ScreenCloud"
                    />
                  )}
                />
                {/* System routes */}
                <Route
                  exact
                  path={`/system/login/:systemAccessId`}
                  render={(props) => (
                    <ComponentLoader
                      contextName={Context.System}
                      componentName={"SystemLogin"}
                      title="System Login - ScreenCloud"
                    />
                  )}
                />
                <Route
                  exact
                  path={`/migrate/:orgId`}
                  render={(props) => {
                    const { orgId } = props.match.params;
                    if (orgId && isUuid(orgId)) {
                      return (
                        <ComponentLoader
                          contextName={Context.CustomerMigration}
                          componentName={"CustomerMigration"}
                          title="Customer Migrate - Content"
                        />
                      );
                    } else {
                      return <div>This is not an org you are looking for,</div>;
                    }
                  }}
                />
                <Route path="*" component={GenericNotFound} />
              </Switch>
            )}
          </div>
        </div>
        <div className="blocker">
          <div className="message">
            <ImageBlocker />
            <div className="blocker-note">
              <h3>
                <FormattedMessage
                  id="common.text.message-block-heading"
                  defaultMessage="Studio is easier to manage on a bigger screen, you may want to switch to a larger screen type."
                />
              </h3>
              <p>
                <FormattedMessage
                  id="common.text.message-block-paragragh-1"
                  defaultMessage="If you are on mobile device, try to rotating your device."
                />
              </p>
              <p>
                <FormattedMessage
                  id="common.text.message-block-paragragh-2"
                  defaultMessage="Need help? Please contact"
                />{" "}
                <a
                  href={ScreenCloudContactUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FormattedMessage
                    id="common.text.our-support"
                    defaultMessage="our support"
                  />
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withData(RouteController) as React.ComponentType<Props>;
