import { useState } from "react";
import { StrictDropdownItemProps } from "semantic-ui-react";

import { getClient } from "src/billinglatest/clients/service.client";

import { Constants } from "src/billinglatest/constants";
import { util } from "src/billinglatest/util";
import { useAppContext } from "src/hooks/useAppContext";
import * as api from "@screencloud/billing-client-api";

import { CardNetwork, PaymentType, HookProps, PaymentSource } from "src/billinglatest/types";

/**
 * This mapper function currently does nothing, as the api type is the same as the UI type.
 * However, it's here to allow for future changes to the API without affecting the UI, severing the
 * dependency between the two. If we decide in future to make a change to the API, we can do so without
 * affecting the UI, as long as we update this mapper function to map the new API type to the existing UI type.
 *
 * @param paymentSource - PaymentSource object from the API
 */
export const mapFromApi = (paymentSource: api.PaymentSources.PaymentSource): PaymentSource => {
  return paymentSource;
};

export interface UsePaymentSource {
  name: () => string;
  summary: () => string;
  isCard: () => boolean;
  isPaypalExpressCheckout: () => boolean;
  isBankAccount: () => boolean;
  isInvoiced: () => boolean;
  card: () => Required<PaymentSource>["card"];
  payPalExpressCheckout: () => Required<PaymentSource>["paypal"];
  bankAccount: () => Required<PaymentSource>["bankAccount"];
  invoiced: () => Required<PaymentSource>["invoice"];
  brand: () => PaymentType | CardNetwork;
  canPayVia: (currencyCode: string) => StrictDropdownItemProps[];
  set: (input: PaymentSource | null) => void;
  get: () => PaymentSource | null;
  exists: () => boolean;
  fetch: () => Promise<void>;
  refetch: () => Promise<void>;
}

export function usePaymentSource(props?: HookProps): UsePaymentSource {
  const context = useAppContext();
  const [_get, _set] = useState<PaymentSource | null>(null);

  /**
   * Retrieve a user-friendly name for the payment source.
   */
  const name = (type?: PaymentType): string => {
    switch (type || get()?.type || "") {
      case "card":
        return "Credit Card";
      case "direct_debit":
        return "Direct Debit";
      case "paypal":
        return "PayPal";
      case "invoice":
        return "Invoiced";
      default:
        return "Unknown Payment Method";
    }
  };

  /**
   * Obtain a concise summary of the payment details identifiers.
   *
   * @remarks
   * Returns a one-liner that provides key details of the payment source, such as the last 4 digits of a card, the
   * bank name, PayPal email address, and more.
   */
  const summary = (): string => {
    if (isCard()) {
      const bullet = "\u2022";
      return `${bullet}${bullet}${bullet}${bullet}${card().last4}`;
    }
    if (isPaypalExpressCheckout()) {
      return payPalExpressCheckout().email;
    }
    if (isBankAccount()) {
      const account = bankAccount();
      return `${account.bank} ${account.last4}`;
    }
    if (isInvoiced()) {
      return invoiced().email;
    }
    return "";
  };

  /**
   * Determines if the payment source is a credit card.
   *
   * @remarks
   * Will also return false if there is no payment source available.
   */
  const isCard = (): boolean => {
    return get()?.type === "card" || false;
  };

  /**
   * Determines if the payment source is a paypal express checkout account.
   *
   * @remarks
   * Will also return false if there is no payment source available.
   */
  const isPaypalExpressCheckout = (): boolean => {
    return get()?.type === "paypal" || false;
  };

  /**
   * Determines if the payment source is a direct debit account.
   *
   * @remarks
   * Will also return false if there is no payment source available.
   */
  const isBankAccount = (): boolean => {
    return get()?.type === "direct_debit" || false;
  };

  /**
   * Determines if the payment source is invoiced payment.
   *
   * @remarks
   * Will also return false if there is no payment source available.
   */
  const isInvoiced = (): boolean => {
    return get()?.type === "invoice" || false;
  };

  /**
   * Retrieve the credit card details.
   *
   * @remarks
   * This function throws an error if the payment source is not of credit card type.
   */
  const card = () => {
    if (!isCard()) {
      throw new Error(
        "The payment source you are attempting to retrieve card details from is not associated with a credit card payment type."
      );
    }
    return get()!.card as Required<PaymentSource>["card"];
  };

  /**
   * Retrieve the PayPal Express Checkout details.
   *
   * @remarks
   * This function throws an error if the payment source is not of PayPal Express Checkout type.
   */
  const payPalExpressCheckout = () => {
    if (!isPaypalExpressCheckout()) {
      throw new Error(
        "The payment source you are attempting to retrieve PayPal Express Checkout details from is not associated with a PayPal Express Checkout payment type."
      );
    }
    return get()!.paypal as Required<PaymentSource>["paypal"];
  };

  /**
   * Retrieve the bank account details.
   *
   * @remarks
   * This function throws an error if the payment source is not of bank account type.
   */
  const bankAccount = () => {
    if (!isBankAccount()) {
      throw new Error(
        "The payment source you are attempting to retrieve bank account details from is not associated with a bank account payment type."
      );
    }
    return get()!.bankAccount as Required<PaymentSource>["bankAccount"];
  };

  /**
   * Retrieve the invoiced details.
   *
   * @remarks
   * This function throws an error if the payment source is not of invoiced type.
   */
  const invoiced = () => {
    if (!isInvoiced) {
      throw new Error(
        "The payment source you are attempting to retrieve invoiced details from is not associated with a invoiced payment type."
      );
    }
    return get()!.invoice as Required<PaymentSource>["invoice"];
  };

  /**
   * Retrieve the appropriate brand for the logo representation.
   *
   * @remarks
   * This method provides the brand value applicable not only to credit card payments but also for other payment types.
   * It is recommended for displaying the logo associated with the payment source. It will return an empty string for
   * unknown types.
   */
  const brand = (): PaymentType | CardNetwork => {
    if (isCard()) {
      return card().brand;
    }
    if (isPaypalExpressCheckout()) {
      return "paypal";
    }
    if (isBankAccount()) {
      return "direct_debit"; // Need to see where we can find SEPA in the payload here?
    }
    if (isInvoiced()) {
      return "invoice";
    }
    return "ach";
  };

  /**
   * Retrieves a dropdown list of allowed payment types based on the customer's currency code.
   *
   * @param currencyCode - The currency code associated with the customer's subscription.
   *
   * @remarks
   * The available payment methods for a customer may vary depending on certain factors. This function retrieves and
   * returns the list of allowed payment types specific to the customer's currency code.
   */
  const canPayVia = (currencyCode: string): StrictDropdownItemProps[] => {
    const allowed: Record<string, string> = {
      card: name("card"),
      paypal_express_checkout: name("paypal"),
    };
    if (Constants.DirectDebitCurrencies.includes(currencyCode)) {
      allowed.direct_debit = name("direct_debit");
    }
    // TODO: May nee to condition this when we do self serve migration bits.
    allowed.invoice = name("invoice");
    return util.object.toDropdown(allowed);
  };

  /**
   * Essential Methods.
   *
   * This section includes essential methods that form the core of the hook's functionality. These methods are crucial
   * and are unlikely to require any updates in the future.
   */

  /**
   * Set the payment source.
   */
  const set = (input: PaymentSource | null): void => {
    return _set(input);
  };

  /**
   * Return the payment source.
   */
  const get = (): PaymentSource | null => {
    return _get;
  };

  /**
   * Does the customer have a payment source?
   */
  const exists = (): boolean => {
    return get() !== null;
  };

  /**
   * Fetches the payment source from the billing service.
   *
   * @remarks
   * This function retrieves the payment source from the billing service and stores it within the hook.
   */
  const fetch = async (): Promise<void> => {
    const response = await getClient()?.paymentsources.getPaymentSource(context.currentSpace?.id);
    if (response && response.length > 0) {
      set(mapFromApi(response[0]));
    }
  };

  /**
   * A shortcut for the `fetch` function.
   *
   * @remarks
   * This function acts as a convenient alternative to the fetch function. Calling refetch() produces the same outcome
   * as calling fetch()
   */
  const refetch = async (): Promise<void> => {
    return fetch();
  };

  /**
   * Return the hook
   */
  return {
    name,
    summary,
    isCard,
    isPaypalExpressCheckout,
    isBankAccount,
    isInvoiced,
    card,
    payPalExpressCheckout,
    bankAccount,
    invoiced,
    brand,
    canPayVia,
    set,
    get,
    exists,
    fetch,
    refetch,
  };
}
