import { ApolloQueryResult, ObservableSubscription } from "@apollo/client";
import { IFetchMediaPolicyOutput } from "@screencloud/studio-media-client";
import { StudioPermissions } from "@screencloud/studio-permissions";
import { StudioRegions } from "@screencloud/studio-regions";
import * as Sentry from "@sentry/browser";
import * as React from "react";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  getSubscription,
  getPlan,
  getPaymentSource,
} from "src/billinglatest/clients/service.client";

import localStore from "store";
import { appConfig } from "../appConfig";
import { getSpaceNameById } from "../helpers/spaceHelper";

import {
  CurrentFeatureFlag,
  FEATURE_FLAGS_ENUM,
} from "../constants/featureFlag";
import { sortedByName } from "../helpers/sortingHelper";
import client from "../state/apolloClient";
import queryHelper from "../state/helper/query";
import { ssm } from "../state/session/ssm";
import { Roles } from "../state/session/StudioSession";
import {
  AllSpaceIdsAndNamesDocument,
  AllSpaceIdsAndNamesQuery,
  Channel,
  CurrentUserDocument,
  CurrentUserQuery,
  Maybe,
  MigrationCheckDocument,
  MigrationCheckQuery,
  OrgConnection,
  Space,
  SpaceByIdDocument,
  SpaceByIdQuery,
  UpdateUserSettingsMutationVariables,
  User,
  WhiteLabel,
} from "../types.g";
import { provider } from "src/billinglatest/util/provider";

import {
  isFeatureDisabledByUrl,
  isFeatureEnabledByUrl,
} from "../utils/featureFlag";
import { AppContext } from "./AppContext";
import { getStudioPermissionByUser } from "./getStudioPermissionsByUser";
import ModalsView from "./modals";
import { ModalContextProvider } from "./modals/ModalContext";
import { IModalManager } from "./modals/type";
import { AppContextState } from "./type";
import {
  getGainsightUserIdentity,
  isFirstTimeLoggedIn,
  isScreenCloudUser,
} from "../domains/user/user";
import { AuthServiceErrorResponse } from "@screencloud/auth-sdk";
import { SecureMediaPolicyProvider } from "./components/SecureMediaPolicyProvider";
import { SECURE_MEDIA_API_PATH } from "src/constants/constants";
import Loading from "src/components/Loading";
import { isSelfServeMigrationSession } from "src/domains/customerMigration/customerMigration";
import unsubGainsight from "src/domains/user/unSubGainsight";
import { appendAllowedQueryParams } from "src/helpers/autoPairingCodeHelper";
import { isEqual } from "lodash";

export type { AppContextProps, AppContextState } from "./type";

export interface AppContextApolloProps
  extends RouteComponentProps<any>,
    InjectedIntlProps {}
export interface AppContextProviderProps {
  children?: React.ReactNode;
  // This might be null in case of migration
  currentUser: Maybe<User>;
  initialFeatureFlags: CurrentFeatureFlag;
}

interface AppIntenalState {
  isDataLoaded: boolean;
  studioToken: string | null;
}

interface State extends AppContextState, AppIntenalState {}

class AppContextProvider extends React.Component<
  AppContextProviderProps & AppContextApolloProps,
  State
> {
  get sessions() {
    return ssm;
  }

  get user() {
    return ssm.current;
  }

  public get permissions() {
    return this.state.currentPermissions;
  }

  protected studioRegions: StudioRegions;
  private currentUser: Maybe<User>;
  private spaceById: Space | null;
  private allSpacesSubscription: ObservableSubscription | null;
  constructor(
    props: AppContextProviderProps & AppContextApolloProps,
    state: AppContextState
  ) {
    super(props);

    this.studioRegions = new StudioRegions({
      regions: appConfig.regions.all,
    });

    this.state = {
      allSpaces: [],
      currentPermissions: null!,
      currentOrg: null!,
      currentSpace: null!,
      currentUser: props.currentUser,
      featureFlags: [],
      whiteLabel: null,
      getCurrentUserAndSpaces: this.getCurrentUserAndSpaces,
      migrationOrgIds: [],
      history: props.history,
      intl: props.intl,
      location: props.location,
      logout: this.logout,
      match: props.match,
      modal: {} as IModalManager,
      refetchCurrentOrg: this.refetchCurrentOrg,
      refetchCurrentUser: this.refetchCurrentUser,
      refetchUserStatus: this.refetchUserStatus,
      refetchFeatureFlag: this.refetchFeatureFlag,
      refetchWhiteLabel: this.refetchWhiteLabel,
      refetchCurrentActiveSpace: this.refetchCurrentActiveSpace,
      refetchOrgConnection: this.refetchOrgConnection,
      sessions: this.sessions,
      shouldShowFeature: this.shouldShowFeature,
      user: this.user,
      isDataLoaded: false,
      getDefaultRedirectPath: this.getDefaultRedirectPath,
      redirectToDefaultPath: this.redirectToDefaultPath,
      canvasAppId: "",
      currentOrgStartableChannels: [],
      isCreditcardPayment: null,
      isActiveUser: false,
      studioToken: null,
      orgConnection: null,
      subscription: null,
      hasSbbFlag: this.hasSbbFlag,
    };
  }

  public async componentDidMount() {
    const { token, claims } = await ssm.getAuthData();
    const { currentUser } = this.state;
    const isActiveUser = claims?.status === "active";
    this.setState({ isActiveUser });

    this.setState({
      studioToken: token,
    });

    await this.watchAllSpaces();
    if (token) {
      this.studioRegions.getByAuthToken({ authToken: token }).then((r) => {
        this.setState({ ...r });
      });
    }

    const [contextData, whiteLabel] = await Promise.all([
      queryHelper.getContextProviderData(),
      this.getWhiteLabel(),
    ]);

    const currentOrg = contextData?.currentOrg;
    const canvasAppId = contextData?.allApps?.nodes[0]?.id;
    const orgConnection = contextData?.currentOrg?.orgConnectionByOrgId;
    const currentOrgStartableChannels = (contextData
      ?.currentOrgStartableChannels?.nodes ?? []) as Channel[];
    const migrationOrgIds = contextData?.migrationCheck;

    // For Signage Migration: Newly registered email.
    // No org. No need to do any org related steps.
    if (currentOrg === null) {
      this.setState({
        featureFlags: [] as CurrentFeatureFlag,
        currentOrg,
        canvasAppId,
        isDataLoaded: true,
        whiteLabel: null,
        migrationOrgIds: [],
        currentPermissions: getStudioPermissionByUser(
          this.props.currentUser,
          this.user.settings.spaceId
        ),
        currentOrgStartableChannels: [] as Channel[],
        isActiveUser,
      } as AppContextState & AppIntenalState);
      return;
    }

    Sentry.configureScope(function (scope) {
      scope.setUser({ id: currentOrg?.id });
    });

    /**
     * Retrieve subscription information from the Billing Service.
     */
    const subscription = await getSubscription(this.state.currentSpace?.id);
    const trialStart: number | null = subscription.trialStart || null;
    const trialEnd: number | null = subscription.trialEnd || null;
    const activatedAt: number | null = subscription.activatedAt || null;

    // set gainsight identity tags
    const wind = window as any;
    const accountIdentity = {
      id: currentUser?.orgId, // Required
      name: currentUser?.orgByOrgId?.name,
      subscriptionStatus: subscription?.status,
      trialStartDate: trialStart
        ? new Date(trialStart * 1000).toISOString()
        : null,
      trialEndDate: trialEnd ? new Date(trialEnd * 1000).toISOString() : null,
      activatedAt: activatedAt
        ? new Date(activatedAt * 1000).toISOString()
        : null,
    };

    const userIdenity = await getGainsightUserIdentity(
      currentUser,
      this.props.initialFeatureFlags
    );

    !!wind?.aptrinsic &&
      wind.aptrinsic("identify", userIdenity, accountIdentity);

    await this.updateLastAccessSpaceSession(this.props.currentUser);
    const { email } = ssm.current.claims;

    /**
     * Declare variables as reassignable, allowing them to potentially remain undefined.
     */
    let featureFlags = [] as CurrentFeatureFlag;
    let hasPlan: boolean = false;

    /**
     * Retrieve the billing plan for Chargebee customers.
     *
     * @remarks
     * For non-Chargebee customers (e.g., Azure), we disregard details about the billing plan, and in some cases, may
     * not have that information. We gracefully handle any potential errors, as organisations migrating from Signage
     * may encounter issues creating billing data, and throwing an error would disrupt the migration process.
     */
    if (provider.isChargebee()) {
      try {
        hasPlan = !!(await getPlan(this.state.currentSpace?.id));
      } catch (error) {
        Sentry.captureException(error);
      }
    }

    /**
     * Retrieve the feature flags for the current customer.
     *
     * @remarks
     * We explicitly enter this condition for non-Chargebee customers (e.g., Azure) since their plan will always be
     * falsy above, yet non-Chargebee customers still require their feature flags. These conditions ensure that
     * migrating and e2e users do not receive flags.
     */
    if (
      (provider.isNotChargebee() || hasPlan) &&
      ssm.current.claims.role === Roles.USER &&
      !email?.endsWith("@cypress.next.sc")
    ) {
      featureFlags = this.props.initialFeatureFlags;
    }

    // unsubscribe from gainsight if feature flag enabled
    this.state?.currentUser &&
      !isScreenCloudUser(this.state?.currentUser) &&
      unsubGainsight(client, this.state?.currentUser?.id, featureFlags);

    this.setState({
      featureFlags,
      currentOrg,
      canvasAppId,
      whiteLabel,
      migrationOrgIds,
      isDataLoaded: true,
      currentPermissions: getStudioPermissionByUser(
        this.props.currentUser,
        this.user.settings.spaceId
      ),
      currentOrgStartableChannels,
      orgConnection,
      subscription: subscription || null,
    } as AppContextState & AppIntenalState);

    this.getPaymentSources(this.state.currentSpace?.id);
  }

  public async componentDidUpdate(
    prevProp: AppContextProviderProps,
    prevState: State
  ) {
    if (prevProp.currentUser !== this.props.currentUser) {
      this.setState({
        currentUser: this.props.currentUser,
        currentPermissions: getStudioPermissionByUser(
          this.props.currentUser,
          ssm.current.settings.spaceId
        ),
      });
      this.updateLastAccessSpaceSession(this.props.currentUser);
    }
  }

  public componentWillUnmount() {
    this.allSpacesSubscription?.unsubscribe();
    this.allSpacesSubscription = null;
  }

  public getOrgConnection = async (
    orgId: string | null = null
  ): Promise<OrgConnection | null> => {
    const orgConnection = await queryHelper.getOrgConnectionByOrgId(orgId);
    return orgConnection;
  };

  public refetchOrgConnection = async (): Promise<void> => {
    const orgConnection = await this.getOrgConnection(
      this.state.currentOrg?.id
    );
    if (orgConnection) {
      this.setState({ orgConnection });
    }
  };

  public getWhiteLabel = async (
    spaceId: string | null = null
  ): Promise<Partial<WhiteLabel> | null> => {
    const whiteLabel = await queryHelper.getWhiteLabelBySpaceId(spaceId);
    return whiteLabel;
  };

  public refetchWhiteLabel = async (): Promise<void> => {
    const whiteLabel = await this.getWhiteLabel();
    this.setState({ whiteLabel });
  };

  public refetchCurrentActiveSpace = async (): Promise<void> => {
    const currentSpace = await this.fetchCurrentActiveSpace();
    this.setState({ currentSpace });
  };

  public watchAllSpaces = async () => {
    if (!this.state.user.isGuestOrAnon) {
      if (this.allSpacesSubscription) {
        this.allSpacesSubscription.unsubscribe();
        this.allSpacesSubscription = null;
      }
      this.allSpacesSubscription = client
        .watchQuery({
          query: AllSpaceIdsAndNamesDocument,
          fetchPolicy: "cache-and-network",
        })
        .subscribe({
          next: ({ data }: ApolloQueryResult<AllSpaceIdsAndNamesQuery>) => {
            const allSpaces = data?.allSpaces?.nodes ?? [];
            let currentSpace: Space | null = allSpaces.find(
              (item) => item.id === ssm.current.settings.spaceId
            ) as Space;
            currentSpace = currentSpace
              ? currentSpace
              : allSpaces.length > 0
              ? (allSpaces[0] as Space)
              : null;

            this.setState({
              allSpaces: sortedByName(allSpaces),
              currentSpace,
              currentPermissions: getStudioPermissionByUser(
                this.props.currentUser,
                currentSpace?.id
              ),
            });
          },
          error: (e) => console.error("error = ", e),
        });
    }
  };

  /**
   * Fetch the customers payment sources now.
   *
   * @remarks
   * This is a way of caching the payment sources now for future use. We must ensure that customers who are not using
   * Chargebee as their provider, as well as cases where the customer is empty, do not proceed beyond this point.
   * Allowing them to proceed might lead to errors.
   */
  public getPaymentSources = async (spaceId: string) => {
    if (provider.isNotChargebee() || this.state.user.isGuestOrAnon) {
      return;
    }
    try {
      const paymentSources = await getPaymentSource(spaceId);
      this.setState({
        isCreditcardPayment: paymentSources?.type === "card" || false,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  public getTargetSpace = (currentUser: Maybe<Partial<User>>) => {
    const defaultSpaceId = currentUser?.preferences?.settings?.defaultSpaceId;
    const lastAccessSpaceId =
      currentUser?.preferences?.settings?.lastAccessedSpaceId;
    const isFirstLogin = localStore.get("firstLogin", false);
    const isCurrentSpaceExist = !!this.state.allSpaces.find(
      (space) => space.id === ssm.current.settings.spaceId
    );
    const isLastAccessSpaceExist = !!this.state.allSpaces.find(
      (space) => space.id === lastAccessSpaceId
    );
    const fallbackSpaceId =
      isCurrentSpaceExist && ssm.current.settings.spaceId
        ? ssm.current.settings.spaceId
        : this.state.allSpaces && this.state.allSpaces[0]
        ? this.state.allSpaces[0].id
        : "";

    const targetSpaceId =
      !isFirstLogin && defaultSpaceId && defaultSpaceId !== lastAccessSpaceId
        ? defaultSpaceId
        : isLastAccessSpaceExist
        ? lastAccessSpaceId
        : fallbackSpaceId;
    const targetSpaceName = getSpaceNameById(this.state, targetSpaceId);

    return { spaceId: targetSpaceId, spaceName: targetSpaceName };
  };

  public updateLastAccessSpaceSession = async (
    currentUser: Maybe<Partial<User>>
  ) => {
    const settings = currentUser?.preferences?.settings;
    const defaultSpaceId = settings?.defaultSpaceId;
    const defaultSpaceName = settings?.defaultSpaceName;
    const lastAccessSpaceId = settings?.lastAccessedSpaceId;
    const isFirstLogin = localStore.get("firstLogin", false);
    const { spaceId, spaceName } = this.getTargetSpace(currentUser);

    if (
      !isFirstLogin &&
      defaultSpaceId &&
      defaultSpaceId !== lastAccessSpaceId
    ) {
      const variables: UpdateUserSettingsMutationVariables = {
        input: {
          settingsData: {
            ...settings,
            lastAccessedSpaceId: defaultSpaceId,
            lastAccessedSpaceName: defaultSpaceName,
          },
        },
      };
      await queryHelper.updateUserSettings(variables);
      await this.user.updateSettings({
        spaceId: defaultSpaceId,
        spaceName: defaultSpaceName,
      });
    } else {
      await this.user.updateSettings({
        spaceId,
        spaceName,
      });
    }

    localStore.set("firstLogin", true);
    const currentSpace = await this.getCurrentActiveSpace();
    this.setState({
      currentSpace,
      currentPermissions: getStudioPermissionByUser(
        this.props.currentUser,
        currentSpace?.id
      ),
    });
  };

  public refetchCurrentOrg = async (): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      const org = await queryHelper.getCurrentOrg();
      if (org) {
        this.setState((prevState) => {
          if (!isEqual(prevState.currentOrg, org)) {
            return { currentOrg: org };
          }
          return null; // No state update if the organization is the same
        });
        resolve(true);
      } else {
        reject(false);
      }
    });
  };

  public refetchCurrentUser = async (): Promise<Maybe<User>> => {
    return new Promise(async (resolve, reject) => {
      const currentUser = await queryHelper.getCurrentUser();
      if (currentUser) {
        this.updateLastAccessSpaceSession(currentUser);
        const currentContextUser = {
          ...this.state.currentUser,
          ...currentUser,
        };
        this.setState({ currentUser: currentContextUser });
        resolve(currentContextUser);
      } else {
        reject(currentUser);
      }
    });
  };

  public refetchUserStatus = async () => {
    const result = await ssm.getUserStatus();
    if (
      !(result as AuthServiceErrorResponse).error &&
      (result as { status: string }).status
    ) {
      const status = (result as { status: string }).status;
      // check if isActiveUser has changed? If not do not update state to avoid rerenders
      if (this.state.isActiveUser !== (status === "active")) {
        this.setState({
          isActiveUser: status === "active",
        });
      }
    }
  };

  public refetchFeatureFlag = async () => {
    const featureFlags = await queryHelper.getFeatureFlags(
      this.user.settings.spaceId
    );
    this.setState({ featureFlags });
  };

  public shouldShowFeature = (feature: string): boolean => {
    if ((import.meta.env.REACT_APP_ENV as string) !== "development") {
      return this.state.featureFlags.includes(feature);
    }

    return (
      (!isFeatureDisabledByUrl(feature as FEATURE_FLAGS_ENUM) &&
        isFeatureEnabledByUrl(feature as FEATURE_FLAGS_ENUM)) ||
      this.state.featureFlags.includes(feature)
    );
  };

  public getCurrentUserAndSpaces = async (): Promise<Maybe<User>> => {
    if (this.currentUser) {
      return this.currentUser;
    }

    const { data } = await client.query<CurrentUserQuery>({
      query: CurrentUserDocument,
    });

    this.currentUser = data.currentUser as User;
    return this.currentUser;
  };

  public getmigrationOrgIds = async () => {
    const { data } = await client.query<MigrationCheckQuery>({
      query: MigrationCheckDocument,
    });
    return data.migrationCheck;
  };

  public getCanvasAppId = async () => {
    const canvasAppId = await queryHelper.getCanvasAppId();
    this.setState({ canvasAppId });
  };

  public getDefaultRedirectPath = (permissions: StudioPermissions) => {
    const canReadScreen = permissions.validateCurrentSpace("screen", "read");
    const canReadChannel = permissions.validateCurrentSpace("channel", "read");
    const canReadPlaylist = permissions.validateCurrentSpace(
      "playlist",
      "read"
    );
    const canReadMedia = permissions.validateCurrentSpace("media", "read");
    const canReadLink = permissions.validateCurrentSpace("link", "read");
    const canReadApp = permissions.validateCurrentSpace("app", "read");
    const mainSectionPermissions = [
      { screens: canReadScreen },
      { channels: canReadChannel },
      { playlists: canReadPlaylist },
      { media: canReadMedia },
      { links: canReadLink },
      { apps: canReadApp },
    ];
    const enableSection = mainSectionPermissions.filter((permission) =>
      Boolean(Object.values(permission)[0])
    );
    const canAccessOnlyAdminSection = enableSection.length === 0;
    const defaultRedirect = canAccessOnlyAdminSection
      ? "account"
      : `${Object.keys(enableSection[0])[0]}`;

    return appendAllowedQueryParams(defaultRedirect);
  };

  public redirectToDefaultPath = () => {
    const defaultRedirect = this.getDefaultRedirectPath(
      this.state.currentPermissions
    );
    const basename = ssm.all.length <= 1 ? `/` : `/org/${ssm.current.key}/`;
    window.location.href = `${basename}${defaultRedirect}`;
  };

  public fetchCurrentActiveSpace = async (): Promise<Space | null> => {
    try {
      const { data } = await client.query<SpaceByIdQuery>({
        query: SpaceByIdDocument,
        variables: { spaceId: ssm.current.settings.spaceId },
      });
      return data?.spaceById as Space;
    } catch (error) {
      return null;
    }
  };

  public getCurrentActiveSpace = async (): Promise<Space | null> => {
    if (this.spaceById) {
      return this.spaceById as Space;
    }
    this.spaceById = await this.fetchCurrentActiveSpace();
    return this.spaceById;
  };

  /**
   * Checks for the presence of the Space Based Billing feature flag.
   *
   * @remarks
   * This function determines the correct billing tab for customers based on whether they have the space_based_billing
   * feature flag. Customers with this flag are directed to the legacy UI. It's also important to account for active
   * spaces under a SBB account. Even if these spaces don't explicitly have the flag, they should be treated as if
   * they do.
   */
  public hasSbbFlag = (): boolean => {
    if (this.spaceById && this.spaceById.billingCustomerId) {
      return true;
    }
    return this.shouldShowFeature(FEATURE_FLAGS_ENUM.SPACE_BASED_BILLING);
  };

  public logout = async () => {
    // TODO: This currently logs you out of everything (SSO). Maybe fix, so it only
    // logs you out of the current org?
    ssm.logout();
  };

  public render() {
    if (
      !this.state.isDataLoaded ||
      (!this.state.currentUser && !this.state.user.isGuestOrAnon) ||
      !this.state.featureFlags ||
      !this.props.intl ||
      !this.state.studioToken ||
      (!this.state.currentUser &&
        !isSelfServeMigrationSession(
          this.props.location.pathname,
          this.user?.claims?.role
        ))
    ) {
      // signUp & loging
      if (
        isFirstTimeLoggedIn(this.state.currentUser as User) ||
        localStore.get("isFromLogin") === true
      ) {
        return <Loading withLogo />;
      }
      // other
      return <Loading />;
    }

    const renderAppContextContent = (
      secureMediaPolicy?: IFetchMediaPolicyOutput
    ) => {
      const appContextValue = {
        ...this.state,
        secureMediaPolicy: secureMediaPolicy?.policy,
      };

      return (
        <AppContext.Provider value={appContextValue}>
          <ModalContextProvider>
            <ModalsView
              location={this.props.location}
              history={this.props.history}
              match={this.props.match}
              onModalReady={(modal) => this.setState({ modal })}
            />
          </ModalContextProvider>
          {this.props.children}
        </AppContext.Provider>
      );
    };

    if (
      this.state.featureFlags.includes(FEATURE_FLAGS_ENUM.SECURE_MEDIA_URLS)
    ) {
      return (
        <SecureMediaPolicyProvider
          mediaServiceEndpoint={`${appConfig.secureMediaUrl}${SECURE_MEDIA_API_PATH}`}
        >
          {renderAppContextContent}
        </SecureMediaPolicyProvider>
      );
    } else {
      return renderAppContextContent();
    }
  }
}

export default withRouter(
  injectIntl(DragDropContext(HTML5Backend)(AppContextProvider))
) as React.ComponentType<AppContextProviderProps>;
